<div class="footer flex">
  <div class="flex-auto flex items-center container">
  
    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
      <p style="padding-top: 0.5px; margin-bottom: 0.5px;"> © {{fullyear}} Powred by 
        <a style="color:#ea1a49;" href="https://www.dotcom.tn/">DotCOM V5</a></p>
    </div>
  </div>
</div>





 