<vex-page-layout>

  <vex-secondary-toolbar current="BackOffice">
    <vex-breadcrumbs style="height: var(--toolbar-width);"
     [crumbs]="['Contract Management
     ']"style="margin-top: 0px;" class="flex-auto"></vex-breadcrumbs>
  
  </vex-secondary-toolbar>
  <vex-page-layout-header class="pb-8 flex flex-col items-start justify-center padding"
  style="background-color:whitesmoke;">
  <div [class.container]="layoutCtrl.value === 'fullwidth'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
  class="w-full flex flex-col sm:flex-row justify-between">
  <div>
      
  </div>

 <!--  <div class="hidden sm:block">
    <mat-button-toggle-group [formControl]="layoutCtrl" class="mt-2 sm:mt-0" style="margin-top: -58px;
    margin-bottom: 14px;">
      <mat-button-toggle value="boxed">Boxed</mat-button-toggle>
      <mat-button-toggle value="fullwidth">Full-Width</mat-button-toggle>
    </mat-button-toggle-group>
  </div> -->
</div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.container]="layoutCtrl.value === 'fullwidth'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
    <div class="card overflow-auto -mt-16">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
          <span *ngIf="selection.isEmpty()"></span>
          <span *ngIf="selection.hasValue()">
            {{ selection.selected.length }}
            Contracts<span *ngIf="selection.selected.length > 1">
              s</span> selected</span>
        </h2>
        <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
          <button (click)="deleteCustomers(selection.selected)" color="primary" mat-icon-button
            matTooltip="Delete selected" type="button">
            <mat-icon svgIcon="mat:delete"></mat-icon>
          </button>
          <button color="primary" mat-icon-button matTooltip="Another action" type="button">
            <mat-icon svgIcon="mat:folder"></mat-icon>
          </button>
        </div>
        <div
          class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
          <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
          <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
            placeholder="Search..." type="search">
        </div>


        <span class="flex-1"></span>
        <button [matMenuTriggerFor]="columnFilterMenu" class="ml-4 flex-none" mat-icon-button
          matTooltip="Filter Columns" type="button">
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>
        <button (click)="createCustomer()" class="ml-4 flex-none" color="primary" mat-mini-fab
          matTooltip="Add Contract" type="button">
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>


        
      </div>
      <table @stagger [dataSource]="dataSource"
       class="w-full" mat-table matSort>
        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

        <!-- Text Columns -->
      <!--   <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'Date'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'boolean'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
          </ng-container>

         

        </ng-container> -->





        <!-- Contact Column -->
        <ng-container matColumnDef="contact">
          <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" mat-cell>
            <div class="flex">
              <a (click)="$event.stopPropagation()"
                class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary/10"
                mat-icon-button>
                <mat-icon class="icon-sm" svgIcon="mat:phone"></mat-icon>
              </a>
              <a (click)="$event.stopPropagation()"
                class="w-8 h-8 leading-none flex items-center justify-center ml-1 hover:bg-hover text-teal bg-teal-light"
                mat-icon-button>
                <mat-icon class="icon-sm" svgIcon="mat:mail"></mat-icon>
              </a>
              <a (click)="$event.stopPropagation()"
                class="w-8 h-8 leading-none flex items-center justify-center ml-1 hover:bg-hover text-green bg-green-light"
                mat-icon-button>
                <mat-icon class="icon-sm" svgIcon="mat:map"></mat-icon>
              </a>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th *matHeaderCellDef class="uppercase"
           mat-header-cell mat-sort-header>
            Activate/Desactivate
          </th>
          <td *matCellDef="let row" mat-cell>
            <div class="flex items-center gap-1" (click)="$event.stopPropagation(); activatecontrat(row)"
              (change)="$event ? activatecontrat(row) : null">
              <mat-slide-toggle [checked]="row.active"></mat-slide-toggle>
            </div>
          </td>
        </ng-container>
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] }}
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] }}
            </td>
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'Date'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] }}
            </td>
          </ng-container>
        </ng-container>



       

       







        <!-- Label Column -->
        <ng-container matColumnDef="state">
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            Labels
          </th>
          <td *matCellDef="let row" mat-cell>
            <div
              (click)="$event.stopPropagation()"
              class="flex items-center gap-1"
            >
              <div
                *ngIf="row.state == true"
                [ngClass]="[labels[0].textClass, labels[0].bgClass]"
                class="rounded px-2 py-1 font-medium text-xs flex-none"
              >
                {{ labels[0].text }}
              </div>
              <div
                *ngIf="row.state == false"
                [ngClass]="[labels[1].textClass, labels[1].bgClass]"
                class="rounded px-2 py-1 font-medium text-xs flex-none"
              >
                {{ labels[1].text }}
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Action</th>
          <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
            <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ customer: row }"
              [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
              <mat-icon svgIcon="mat:more_horiz"></mat-icon>
            </button>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="visibleColumns ;sticky: true" mat-header-row></tr>
        <tr (click)="updateContract(row)" *matRowDef="let row; columns: visibleColumns;" @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>
          
      </table>

      <div *ngIf="dataSource.filteredData.length === 0" @scaleFadeIn
    class="flex-auto flex flex-col items-center justify-center">
    <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
    <h2 class="headline m-0 text-center">No Contract matching your filters </h2>
  </div>
    <mat-paginator #paginator
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
    [length]="length"

      class="sticky left-0"></mat-paginator>
  </div>

  </vex-page-layout-content>
</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" 
yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" 
  *ngFor="let column of columns"
    class="checkbox-item mat-menu-item">
    <mat-checkbox (click)="$event.stopPropagation()" 
    [(ngModel)]="column.visible" color="primary">
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>
 

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>

    <button (click)="AffichageContrat(customer)" mat-menu-item>
      <mat-icon svgIcon="mat:info"></mat-icon>
      <span>Details</span>
    </button>

    
    <button (click)="deleteCustomer(customer)" mat-menu-item>
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>Delete</span>
    </button>
  </ng-template>
</mat-menu>


