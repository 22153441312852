<form [formGroup]="formExtraBoarding">
  <h4 class="body-2 text-secondary m-0 title">Add Extra Board</h4><br>
  <mat-divider class="-mx-6 text-border"></mat-divider>
  <div class="flex flex-col">
    <div formArrayName="BoardArrayExtra">
      <div *ngFor="let group of BoardArrayExtra.controls; let i = index" [formGroupName]="i">
        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>Board Name</mat-label>
            <input matInput formControlName="board_name" readonly>
          </mat-form-field>

          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>Period</mat-label>
            <input matInput formControlName="period_name" readonly>
          </mat-form-field>

          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>Board Definition</mat-label>
            <input matInput formControlName="definition" readonly >
          </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>Start_at</mat-label>
            <input matInput formControlName="start_at" readonly [disabled]="true">
            <mat-hint>{{'MM/DD/YYYY' | translate}}</mat-hint>
            <mat-datepicker #formDatepickerRef0></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>End_at</mat-label>
            <input matInput formControlName="end_at" readonly [disabled]="true">
            <mat-hint>{{'MM/DD/YYYY' | translate}}</mat-hint>
            <mat-datepicker #formDatepickerRef4></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>Extra Board</mat-label>
            <input matInput required formControlName="extra_board_name" [matAutocomplete]="autoboardextra">
            <mat-autocomplete #autoboardextra="matAutocomplete" (optionSelected)="onChangeDestinationextraboard($event.option.value, group.get('code').value, i)">
              <mat-option *ngFor="let Arrangement of (filterBoardingLists$[group.get('code').value] | async)" [value]="Arrangement.board_name">
                {{Arrangement.board_name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="group.get('extra_board_name').hasError('required')">
              Extra Board is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Divider conditionally rendered -->
        <mat-divider *ngIf="i < BoardArrayExtra.controls.length - 1" style="border-width: 2px;"  class="my-4 text-border"></mat-divider>
      </div>
    </div>
  </div>
  <div class="flex flex-col sm:flex-row sm:gap-4">
    <div class="float-left" style="margin-left: 83%;">
      <button mat-flat-button mat-button matStepperPrevious>{{'Back' | translate}}</button>
      <button mat-flat-button mat-button color="primary" (click)="saveBoardingextra(formExtraBoarding.value)">{{'Next' | translate}}</button>
    </div>
  </div>
</form>
