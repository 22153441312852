<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 block flex items-center">
   <!--  <img alt="Logo" style="width: 2.5rem;" class="w-8 select-none" src="assets/img/demo/logo.png" /> -->
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">DOTCOM</h1>
  </a>

  <!--   <button *ngIf="isHorizontalLayout$ | async"
          [class.hidden]="mobileQuery"
          [matMenuTriggerFor]="addNewMenu"
          color="primary"
          mat-flat-button
          type="button">Add New
  </button> -->     

  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:person_add"></mat-icon>
      <span>Add Contact</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:done_all"></mat-icon>
      <span>Add Task</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>
      <span>Add Project</span>
    </button>

    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon svgIcon="mat:ballot"></mat-icon>
      <span>Add Document</span>
    </button>
  </mat-menu>

  <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:receipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>

 <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button *ngIf="isHorizontalLayout$ | async" [class.hidden]="mobileQuery" (click)="openMegaMenu(megaMenuOriginRef)"
      [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }" color="primary" mat-button type="button">
      <span>Menu</span>
      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
    </button>
  </div> 


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <!--     <div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div> -->

    <!--    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div> -->

    <!-- <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div> -->
<!--     <div class="px-1" style="padding: 10px;">
    <mat-icon color="primary"class="mat-icon 
    notranslate icon-xl mat-primary"aria-hidden="true" svgIcon="mat:monetization_on" 
   >

  </mat-icon>
    </div> -->




    <div class="px-1">
     
      <div  style=" 
      padding: 10px;
      display: inline-block;
      font-size: 15px;
      ">    {{ convertedAmount | number:'1.2-2' }}
</div>  
      
      <button [matMenuTriggerFor]="DeviseMenu" mat-icon-button type="button"  >
<!--         <img alt="Logo" style="width: 1.5rem;" class="w-8 select-none" src="assets/img/demo/devises.png" />
 -->      <span>{{DeviseCurrency}}</span>     

      </button>

    </div>
    <mat-menu #DeviseMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="changeDevise('EUR')">
        <span>EUR</span>
      </button>
      <button mat-menu-item (click)="changeDevise('USD')">
        <span>USD</span>
      </button>
       <button mat-menu-item (click)="changeDevise('TND')">
         <span>TND</span>     
      </button>  
      
      <button mat-menu-item (click)="changeDevise('DZD')">
         <span>DZD</span>     
      </button>

      <button mat-menu-item (click)="changeDevise('LYD')">
         <span>LYD</span>     
      </button>

      <button mat-menu-item (click)="changeDevise('EGP')">
         <span>EGP</span>     
      </button>
    </mat-menu>



    
    <div class="px-1">
     
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon svgIcon="flag:united-states"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="changeLang('fr')">
        <mat-icon svgIcon="flag:France"></mat-icon>
        <span>Francais</span>
      </button>
      <button mat-menu-item (click)="changeLang('en')">
        <mat-icon svgIcon="flag:united-states"></mat-icon>
        <span>English</span>
      </button>
      <button mat-menu-item (click)="changeLang('ar')">
        <mat-icon svgIcon="flag:Tunisia"></mat-icon>
        <span>Arabe</span>
      </button>
    </mat-menu>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"></vex-navigation>