import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { ApiService } from '../api.service';

@Component({
  selector: 'vex-extra-board-step',
  templateUrl: './extra-board-step.component.html',
  styleUrls: ['./extra-board-step.component.scss']
})
export class ExtraBoardStepComponent implements OnInit,OnChanges  {
  @Input() formExtraBoarding: FormGroup;
  filterBoardingLists$: { [key: string]: Observable<any[]> } = {};

  private boardingLists: { [key: string]: any[] } = {
    0: [],
    1: [],
  };
    @Input() periods:any[]
  
  constructor( 
  private formBuilder: FormBuilder,private ContratService: ApiService, ) {
    
  }
  ngOnInit(): void {

 

}
ngOnChanges(changes: SimpleChanges): void {
  if (changes['periods'] && this.periods && this.periods.length > 0) {
    console.log("we are in the extra board child");
    console.log("extra board child", this.periods);
    if(this.periods.length!=1){
    this.periods.forEach((item,index)=>{
      this.GetBoardextra(item.contract_id, item.code);


    })
  }
    console.log("responseboardextra filterBoardingLists ", this.filterBoardingLists$)

  }
}

public get BoardArrayExtra(): FormArray {
  return this.formExtraBoarding.get("BoardArrayExtra") as FormArray;

}
GetBoardextra(IdContrat, Code) {
  console.log("responseboardextra IdContrat--", IdContrat)
  console.log("responseboardextra CodePeriod--", Code)

  this.ContratService.getboardofcontratPeriod(IdContrat, Code).subscribe(responseboardextra => {
    console.log("responseboardextra: ", responseboardextra)
    if (!responseboardextra.error) {

      const BoardExtra = responseboardextra;
      this.filterBoardingLists$[Code] = of([...BoardExtra]);
     
    }
  })
}
saveBoardingextra(value: any) {
  if (this.formExtraBoarding.invalid) {
    return;
  }
  else{
    this.ContratService.updateBoardExtraofcontrat(value).subscribe(responseboardextra => {
      console.log("responseboardextra: ", responseboardextra)
     
    })
    
  }
console.log("saveBoardingextra  "+ JSON.stringify(value)) 
}




onChangeDestinationextraboard(selected, code,index) {

  var selectedboardchild 
  this.filterBoardingLists$[code].pipe(
    map(boardArray => boardArray.find(e => e.board_name === selected))
  ).subscribe(selectedBoardChild => {
    selectedboardchild = selectedBoardChild;
  });   
  console.log("onChangeDestinationextraboard  "+ JSON.stringify(selectedboardchild)) 



 

    const extra_board_id = this.BoardArrayExtra.at(index).get('extra_board_id');
    const extra_board_name = this.BoardArrayExtra.at(index).get('extra_board_name');

    extra_board_id.setValue(selectedboardchild.board_id);

    extra_board_name.setValue(selectedboardchild.board_name);

}
}
