<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto"></h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-stepper style="color:black"  #stepper>
  <mat-step
    class="mat-horizontal-stepper-header mat-step-header mat-focus-indicator mat-primary ng-tns-c2-0 ng-star-inserted">
    <form [formGroup]="formContrat">
      <ng-template matStepLabel>{{'Contract' | translate}}</ng-template><br>
      <h4 class="body-2 text-secondary m-0 title ng-tns-c304-238">{{'Contrat informations' | translate}}*</h4><br>
      <mat-divider class="-mx-6 text-border"></mat-divider>
      <div class="flex flex-col">
        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>{{'Contract Name' | translate}} </mat-label>
            <input  matInput formControlName="name" required
              [matAutocomplete]="auto9">
            <mat-autocomplete #auto9="matAutocomplete" >
              <mat-option *ngFor="let contrat of filterContrat$| async" [value]="contrat.name">
                {{contrat.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="formContrat.get('name').hasError('required')">{{'Name of Contract is required' |
              translate}}</mat-error>
          </mat-form-field>
          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>{{'Hotel Name' | translate}}</mat-label>
            <input matInput formControlName="hotel_name" readonly>
        
          </mat-form-field>
      
          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>{{'From' | translate}} </mat-label>
            <input matInput formControlName="start_at"  [min]="minFromDate" 
            (click)="formDatepickerRef.open()"
              (focus)="formDatepickerRef.open()" [matDatepicker]="formDatepickerRef"
              (dateChange)="changeBeginCotrat($event)" readonly required>
            <mat-error *ngIf="formContrat.get('start_at').hasError('required')">{{'Date is required' |
              translate}}</mat-error>
            <mat-hint>{{'MM/DD/YYYY' | translate}}</mat-hint>
            <mat-datepicker #formDatepickerRef></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>{{'To' | translate}} </mat-label>
            <input appearance="fill" matInput formControlName="end_at" 
             [min]="mintoDate"
            (click)="formDatepickerRef1.open()"
              (focus)="formDatepickerRef1.open()" [matDatepicker]="formDatepickerRef1"
              (dateChange)="changeEndContrat($event)" readonly required>
            <mat-error *ngIf="formContrat.get('end_at').hasError('required')">{{'Date is required' |
              translate}}</mat-error>
            <mat-hint>{{'MM/DD/YYYY' | translate}}</mat-hint>
            <mat-datepicker #formDatepickerRef1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>{{'Version' | translate }}</mat-label>
            <input matInput required formControlName="version" type="number" >
            <mat-error *ngIf="formContrat.get('version').hasError('required')">{{'Version is required' |
              translate}}</mat-error>
          </mat-form-field>

          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>{{'Access' | translate}}</mat-label>
            <mat-select formControlName="access">
              <mat-option *ngFor="let Access of Accesslist" [value]="Access.value">
                {{Access.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error class="form-text text-danger" *ngIf="formContrat.get('access').errors?.['required'] ">
              {{'Access is required' | translate }}
            </mat-error>
          </mat-form-field>
     

          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>{{'Currency' | translate}}</mat-label>
            <mat-select formControlName="currency">
              <mat-option *ngFor="let Devise of DeviseList" [value]="Devise.value">
                {{Devise.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error class="form-text text-danger" *ngIf="formContrat.get('currency').errors?.['required'] ">
              {{'Currency is required' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="flex-1" appearance="fill">
            <mat-label>{{'Market' | translate}}</mat-label>
            <input matInput formControlName="market_name" type="text" 
              [matAutocomplete]="automarket" required class="form-control" 
              class="form-control">
            <mat-autocomplete #automarket="matAutocomplete"
              (optionSelected)="onChangeDestinationmarket($event.option.value)">
              <mat-option *ngFor="let market of filteredMarket$| async " [value]="market.name">
                <span class="body-1">{{ market.name }}</span>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="formContrat.get('market').hasError('required')">{{'Market of Contract is required' |
              translate}}</mat-error>
          </mat-form-field>
        </div>
        <br>
      </div>
      <div class="flex flex-col sm:flex-row sm:gap-4">
        <div class="float-left" style="margin-left: 87%;">
          <button mat-flat-button mat-button color="primary" (click)="updateContrat()">{{'Update Contract' |
            translate}}</button>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <form [formGroup]="formPeriode">
      <ng-template matStepLabel>{{'Period' | translate}}</ng-template><br>
      <h4 class="body-2 text-secondary m-0 title ng-tns-c304-238"> {{'Period informations' | translate}}*</h4><br>
      <mat-divider class="-mx-6 text-border"></mat-divider>
      <div class="flex flex-col">
        <div class="flex flex-col sm:flex-row sm:gap-4"  *ngIf="showNumP">
          <input formControlName="id" style="display: none;">
          <mat-form-field class="flex" appearance="fill">
            <mat-label>{{'Number Period' | translate}}</mat-label>
            <input (input)="blurEvent($event)" matInput required formControlName="NBperiode" type="number">
            <!-- <mat-error *ngIf="formPeriode.get('NBperiode').hasError('required')">{{'Number Period is required' |
              translate}}</mat-error> -->
          </mat-form-field>
        </div>
        <div formArrayName="PeriodeArray" class=" sm:flex-row sm:gap-2">
          <div *ngFor="let item of PeriodeArray.controls;let j=index" [formGroupName]="j"
            class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-1" appearance="fill">
              <mat-label>{{'Code Period' | translate}}</mat-label>
              
              <input matInput class="form-control" id="code" minlength="1" 
              
                 formControlName="code" required name="code"   readonly

                >
                <mat-error *ngIf="PeriodeArray.at(j).get('code').hasError('required')">{{'code is required' |
                  translate}}</mat-error>
             
            </mat-form-field>

            <mat-form-field class="flex-1" appearance="fill">
              <mat-label>{{'Begin' | translate}} </mat-label>
              <input matInput formControlName="start_at" (click)="formDatepickerRef3.open()"
                (focus)="formDatepickerRef3.open()" [matDatepicker]="formDatepickerRef3"
                [min]="minFromDate" 
                [max]="mintoDate"
                (dateChange)="changeCheckInn1($event,j)" readonly required>
              <mat-error *ngIf="PeriodeArray.at(j).get('start_at').hasError('required')">{{'Begin is required' |
                translate}}</mat-error>
              <mat-hint>{{'MM/DD/YYYY' | translate}}</mat-hint>
              <mat-datepicker #formDatepickerRef3></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="flex-1" appearance="fill">
              <mat-label>{{'End' | translate}} </mat-label>
              <input matInput formControlName="end_at" (click)="formDatepickerRef2.open()"
              [min]="minFromDate" 
              [max]="mintoDate"
                (focus)="formDatepickerRef2.open()" [matDatepicker]="formDatepickerRef2"
                (dateChange)="changeCheckInn2($event,j)" readonly required>
              <mat-error *ngIf="PeriodeArray.at(j).get('end_at').hasError('required')">{{'End is required' |
                translate}}</mat-error>
              <mat-hint>{{'MM/DD/YYYY' | translate}}</mat-hint>
              <mat-datepicker #formDatepickerRef2></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="flex-1" appearance="fill">
              <mat-label>{{'Name Period' | translate}}</mat-label>
              <input matInput formControlName="name" required>
              <mat-error *ngIf="PeriodeArray.at(j).get('name').hasError('required')">{{'Name Period is required' |
                translate}}</mat-error>
            </mat-form-field>
            <div>
              <button matInput class="flex-1" mat-stroked-button color="warn" (click)="RemovePeriode(j)">- {{ 'Remove' |
                translate}}</button>
            </div>
           
          </div>
        </div> <br>
        <div>
          <button mat-stroked-button color="primary" (click)="AddnewNullPeriod(null,PeriodeArray.value.length+1)">+ {{'Add New' |
            translate}}</button>
        </div>
      </div> <br>
      <div class="flex flex-col sm:flex-row sm:gap-4">
        <div class="float-left" style="margin-left: 88%;">
          <button mat-flat-button mat-button color="primary" (click)="savePeriode()">{{'Update Periode' |
            translate}}</button>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <form [formGroup]="formRooms">
      <ng-template matStepLabel>Rooms</ng-template><br>
      <h4 class="body-2 text-secondary m-0 title ng-tns-c304-238"> {{'Rooms informations' | translate}}*</h4><br>
      <mat-divider class="-mx-6 text-border"></mat-divider>
      <div class="flex flex-col">
        <div formArrayName="RoomArray" *ngFor="let item of RoomArray.controls;let j=index">
          <div [formGroupName]="j">

            <div class="flex flex-col sm:flex-row sm:gap-4">
              
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Room Name' | translate}}</mat-label>
                <input matInput formControlName="room_name" [matAutocomplete]="auto2" required>
                <mat-autocomplete
                  #auto2="matAutocomplete" (optionSelected)="onChangeRoomName($event.option.value,j)">
                  <mat-option *ngFor="let room of fltDestinationsForContrat$| async" [value]="room.name">
                    {{room.name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="RoomArray.at(j).get('room_name').hasError('required')">Room Name is required</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Room Type' | translate}}</mat-label>
                <input matInput formControlName="room_type_name" required [matAutocomplete]="auto3">
                <mat-autocomplete #auto3="matAutocomplete"
                  (optionSelected)="onChangeContratRoomType($event.option.value,j)">
                  <mat-option *ngFor="let roomType of filterRoomTypeContrat$| async" [value]="roomType.name">
                    {{roomType.name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="RoomArray.at(j).get('room_type_name').hasError('required')">Room Type is required</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Code Room' | translate}}</mat-label>
                <input matInput formControlName="code_room"readonly
                   required>

                <mat-error *ngIf="RoomArray.at(j).get('code_room').hasError('required')">{{'Code Room is required' |
                  translate}}</mat-error> 
              </mat-form-field>
             
            </div>
            <div class="flex flex-col sm:flex-row sm:gap-4">

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Code Type' | translate}}</mat-label>
                <input matInput formControlName="code_type"readonly
                 required >
                <mat-error *ngIf="RoomArray.at(j).get('code_type').hasError('required')">{{'Code Type is required' |
                  translate}}</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Min Pax' | translate}} </mat-label>
                <input matInput formControlName="min_pax" type="number" 
                readonly required >
                <mat-error *ngIf="RoomArray.at(j).get('min_pax').hasError('required')">{{'Min Pax is required' |
                  translate}}</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Max Pax' | translate}}</mat-label>
                <input matInput formControlName="max_pax" type="number" readonly  
                required >
                <mat-error *ngIf="RoomArray.at(j).get('max_pax').hasError('required')">{{'Max Pax Adulte is required' |
                  translate}}</mat-error>
              </mat-form-field>
            </div>
            <div class="flex flex-col sm:flex-row sm:gap-4">

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Min Adulte' | translate}}</mat-label>
                <input matInput formControlName="min_adult" type="number"
                readonly
                 required >
                <mat-error *ngIf="RoomArray.at(j).get('min_adult').hasError('required')">{{'Min Adulte is required' |
                  translate}}</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Max Adulte' | translate}}</mat-label>
                <input matInput formControlName="max_adult" type="number"
                readonly required >
                <mat-error *ngIf="RoomArray.at(j).get('max_adult').hasError('required')">{{'Max Adulte is required' |
                  translate}}</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Child' | translate}}</mat-label>
                <input matInput formControlName="child" type="number"
                readonly  required >
                <mat-error *ngIf="RoomArray.at(j).get('child').hasError('required')">{{'Child is required' |
                  translate}}</mat-error>
              </mat-form-field>
            </div>
            <button matInput class="flex-1" mat-stroked-button color="warn" (click)="RemoveRoom(j)">- {{'Remove' |
              translate}}</button>
          </div><br>
        </div> <br>
        <div>
          <button mat-stroked-button color="primary" (click)="AddnewrowRoom(null)">+ {{'Add New' |
            translate}}</button>
        </div>
      </div> <br>
      <div class="flex flex-col sm:flex-row sm:gap-4">
        <div class="float-left" style="margin-left: 88%;">

          <button mat-flat-button mat-button color="primary" (click)="updateRoom()">{{'Update Room' |
            translate}}</button>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <form [formGroup]="formBoarding">
      <ng-template matStepLabel>{{'Board' | translate}}</ng-template><br>
      <h4 class="body-2 text-secondary m-0 title ng-tns-c304-238"> {{'Add Boarding' | translate}}</h4><br>
      <mat-divider class="-mx-6 text-border"></mat-divider>
      <div class="flex flex-col">
        <div formArrayName="BoardArray">
          <div *ngFor="let item of BoardArray.controls;let i=index" >
          <div [formGroupName]="i">
            <div class="flex flex-col sm:flex-row sm:gap-4">

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>Period </mat-label>
                <input matInput formControlName="period_name" class="input:disabled" required
                [matAutocomplete]="autopeiodBoard"  
              > 
                <mat-autocomplete #autopeiodBoard="matAutocomplete" 
                  (optionSelected)="onChangeDestinationboardPeriod($event.option.value,i)">
                  <mat-option *ngFor="let period of filterBoardingPeriod$ | async" [value]="period.name">
                    {{period.name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="BoardArray.at(i).get('period_name').hasError('required')"> {{'Period is required' | translate}}</mat-error>

               </mat-form-field>
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Board' | translate}}</mat-label>
                <input matInput formControlName="board_name" required (click)="SetPeriodBoardIndex(item)"
                [matAutocomplete]="autoo">
                <mat-autocomplete #autoo="matAutocomplete"
                  (optionSelected)="onChangeDestinationboard($event.option.value,i)">
                  <mat-option *ngFor="let Arrangement of filterBoardingforboarding$[selectedPeriodBoard]| async"
                    [value]="Arrangement.name">
                    {{Arrangement.name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="BoardArray.at(i).get('board_name').hasError('required')"> {{'Board is required' | translate}}</mat-error>

              </mat-form-field>


            


              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Definition' | translate}}</mat-label>
                <input matInput  readonly 
                formControlName="definition" required pattern="[a-zA-Z].*">
                <mat-error *ngIf="BoardArray.at(i).get('definition').hasError('required')"> {{'Definition is required' | translate}}</mat-error>
                <mat-error *ngIf="BoardArray.at(i).get('definition').hasError('pattern')"> {{'Definition start with alphabet' | translate}}</mat-error>
              </mat-form-field>
            </div>



            <div class="flex flex-col sm:flex-row sm:gap-4">
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'From Board' | translate}} </mat-label>
                <input matInput formControlName="start_at" 
                (click)="formDatepickerRef0.open()" [readonly]="inputReadonly" [disabled]="true"
                  (focus)="formDatepickerRef0.open()" [matDatepicker]="formDatepickerRef0"
                   readonly required>
                <mat-error *ngIf="BoardArray.at(i).get('start_at').hasError('required')">From Board is required</mat-error>
                <mat-hint>{{'MM/DD/YYYY' | translate}}</mat-hint>
                <mat-datepicker #formDatepickerRef0></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'To Board' | translate}} </mat-label>
                <input matInput formControlName="end_at" 
                (click)="formDatepickerRef4.open()" [readonly]="inputReadonly"
                  (focus)="formDatepickerRef4.open()" [matDatepicker]="formDatepickerRef4" [disabled]="true"
                   readonly required>
                <mat-error *ngIf="BoardArray.at(i).get('end_at').hasError('required')">{{'To Board is required' | translate}}</mat-error>
                <mat-hint>{{'MM/DD/YYYY' | translate}}</mat-hint>
                <mat-datepicker #formDatepickerRef4 readonly></mat-datepicker>
              </mat-form-field>
            </div>
            <br>
            <button matInput class="flex-1" mat-button color="warn" (click)="RemoveBoard(i)">- Remove
              </button><br>
          </div>
          </div><br>
        </div>
        <div>
          <button mat-button color="primary" (click)="Addnewrow()">+ {{'Add New' | translate}} </button>
        </div>
      </div>

      <div class="flex flex-col sm:flex-row sm:gap-4">
        <div class="float-left" style="margin-left: 86%;">

          <button mat-flat-button mat-button color="primary" (click)="saveBoarding()">{{'Update Boarding' | translate}}</button>
        </div>
      </div>
    </form>
  </mat-step>
  <!-- <mat-step>
    <ng-template matStepLabel>Extra Board</ng-template><br>

  <vex-extra-board-step
  [formExtraBoarding]="formExtraBoarding"
  [periods]="Periodes"
 
></vex-extra-board-step> 
</mat-step> -->

  <mat-step>
    <form [formGroup]="formallotement" style=" display: flex;
    justify-content: center;
    align-items: center; 
     ">

      <ng-template matStepLabel>{{ 'Allotement' | translate }}</ng-template><br>
      <div class="flex flex-col" formArrayName="allotArray">
        <br><br>

        <div class="headers">
          <div class="header-item"> {{ 'Period Id' | translate }} </div>
          <div class="header-item"> {{ 'Period Name' | translate }} </div>
          <div class="header-item"> {{ 'Room Name' | translate }} </div>
          <div class="header-item"> {{ 'Booked Number' | translate }} </div>
          <div class="header-item"> {{ 'Number' | translate }} </div>
        </div>
                <div *ngFor="let item of allotArray.controls; let j = index" [formGroupName]="j">
          <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field appearance="outline" class="flex-1">
            <input matInput formControlName="period_id" placeholder="Period ID" readonly>
          </mat-form-field>
    
          <mat-form-field appearance="outline" class="flex-1">
            <input matInput formControlName="period_name" placeholder="Period Name" readonly>
          </mat-form-field>
    
          <mat-form-field appearance="outline" class="flex-1">
            <input matInput formControlName="room_name" placeholder="Room Name" readonly>
          </mat-form-field>
      
    
          <mat-form-field appearance="outline" class="flex-1">
            <input matInput formControlName="bookedNumber" placeholder="bookedNumber" readonly type="bookedNumber">
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-1">
            <input matInput formControlName="number" placeholder="Number" type="number" required (blur)="saveAllotementNumber($event, j)">
          </mat-form-field>
        </div>
        </div>
        <button mat-flat-button mat-button color="primary" (click)="saveUpdateAllotement()">{{'Update Allotement' | translate}}</button>

      </div>
      <br>
     
    
    </form>
    
  </mat-step>

  <mat-step>

    <form  [formGroup]="formChild"><br>

      <ng-template matStepLabel >{{'Child Prices' | translate}}</ng-template><br>
      <div class="flex flex-col">

        
         

        <div  *ngFor="let items of child_prices.controls;let j=index ">
              <div class="cadre">
                <div formArrayName="child_prices">

          <div class="line" [formGroupName]="j">
            <div class="flex flex-col sm:flex-row sm:gap-4">
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>Choose Period </mat-label>
                <mat-select formControlName="period_name" required (ngModelChange)="onChangePeriodChildPrice($event,j)"
                  >
                  <mat-option [value]="period.name" *ngFor="let period of Periodlist">
                    {{period.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Number Adult' | translate}} </mat-label>
                <input matInput formControlName="adults" type="number" required>
              </mat-form-field>


              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Board' | translate}}</mat-label>
                <input matInput formControlName="board_name"
                required
                  [matAutocomplete]="autoo">
                <mat-autocomplete #autoo="matAutocomplete"
                  (optionSelected)="onChangeDestinationboardForChildPrices($event.option.value,j)">
                  <mat-option *ngFor="let Arrangement of filterBoardingChild$| async" [value]="Arrangement.board_name">
                    {{Arrangement.board_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            
            <div class="flex flex-col sm:flex-row sm:gap-4">

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Room' }}</mat-label>
                <input matInput formControlName="room_name" type="text"
                required
                  [matAutocomplete]="auto2">
                <mat-autocomplete #auto2="matAutocomplete"
                  (optionSelected)="onChangeRoomNameForChildPrices($event.option.value,j)">
                  <mat-option *ngFor="let RoomsChild of fltAllRooms$| async" [value]="RoomsChild">
                    {{RoomsChild.room_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
             

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Room Type' | translate}}</mat-label>
                <input matInput 
                formControlName="type_name" type="text" 
                required
                  [matAutocomplete]="auto3">
                <mat-autocomplete #auto3="matAutocomplete"
                  (optionSelected)="onChangeRoomTypeChildPrice($event.option.value,j)">
                  <mat-option *ngFor="let Room of (filterRoomTypeContratForChild$[j]| async)" [value]="Room.room_type_name">
                    {{Room.room_type_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            
            <h4 class="body-2 text-secondary m-0 title ng-tns-c304-238"> </h4><br>
            <div formArrayName="childs" class="line" 
            *ngFor="let itemchilds of getChildPriceControl(items).controls; let i = index" >
            <div [formGroupName]="i">
<div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-1" appearance="fill">
              <mat-label>{{'Child Number' | translate}} </mat-label>
              <input matInput formControlName="child_number" type="number" required >
            </mat-form-field>
            <mat-form-field class="flex-1" appearance="fill">
              <mat-label>{{'From Age' | translate}} </mat-label>
              <input matInput formControlName="age_from" type="number" required >
            </mat-form-field>

            <mat-form-field class="flex-1" appearance="fill">
              <mat-label>{{'Age To' | translate}} </mat-label>
              <input matInput formControlName="age_to" type="number" required >
            </mat-form-field>

            <mat-form-field class="flex-1" appearance="fill">
              <mat-label>{{'Price' | translate}} </mat-label>
              <input matInput formControlName="price" type="number" required >
            </mat-form-field>
            <mat-form-field class="flex-1" appearance="fill">
              <mat-label>{{'Operation' }}</mat-label>
              <mat-select formControlName="operation"  required>
                <mat-option [value]="option.value" *ngFor="let option of options">
                  {{ option.display }}
                </mat-option>
              </mat-select>
             
            </mat-form-field>

             

    

             </div>
            
             <button matInput class="flex-1"mat-button color="warn"  (click)="RemoveChilds(items,i)">-
              Remove </button></div>
            
          </div>
          <button (click)="AddnewrowChildPrice(items)" style="border-radius: 1;width: fit-content;" mat-button color="primary" type="button"> +{{'Add New Child' | translate}}
          </button> 
        
            <br>
            <button matInput class="flex-1"mat-button color="warn"  (click)="RemoveitemsForChild(j)">-
              Remove </button><br>
          </div>
                    </div>
                    

        </div>
        </div>
        <button (click)="AddnewrowChild(null)" style="border-radius: 1;width: fit-content;" mat-button color="primary" type="button"> +{{'Add New' | translate}}
        </button><br><br>

      <br>
      <div  style="margin-right: 25px;">
        <button mat-button mat-dialog-close type="button">Cancel</button>
        <button  color="primary" mat-flat-button type="submit"
          (click)="saveChild()">{{'Update Child Prices' | translate}}</button>
      </div>
    </div>
    </form> <br><br>
    <br><br>
  </mat-step>

 
  <mat-step>
    <ng-template matStepLabel>{{'Room Prices' | translate}}</ng-template><br>
    <h4 class="body-2 text-secondary m-0 title ng-tns-c304-238"> {{'Base Room Price Entry' | translate}}*</h4><br>
   <br>
    <mat-tab-group [selectedIndex]="selectedTabIndex"
      (selectedIndexChange)="selected.setValue($event);patchValuesForBasePrices(selected.value) ">
      
      <mat-tab *ngFor="let tab of Periodes; let index = index" [label]="tab.code +'.'+tab.name">
        <div class="flex flex-col">
<!-- <****************************************************************************************************> -->

          <form [formGroup]="formBaseRoom" >
            <div formArrayName="basePricePerPeriod">

            <div [formGroupName]="index">

              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Room Name' | translate}} </mat-label>
                  <input matInput type="text" formControlName="room_name"  class="input" required
                    [matAutocomplete]="auto5">
                  <mat-autocomplete #auto5="matAutocomplete"
                    (optionSelected)="onChangeDestinationroomPrices($event.option.value,index)">
                    <mat-option *ngFor="let Room of fltAllRooms$| async" [value]="Room">
                      {{Room.room_name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error class="form-text text-danger" *ngIf="basePricePerPeriod.at(index).get('room_name').errors?.['required'] ">
                    {{'Room is required' | translate}}
                  </mat-error>
                </mat-form-field>
  
                <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Room Type' | translate}}</mat-label>
                  <input matInput formControlName="room_type_name"  id="RoomType" required
                    [matAutocomplete]="auto4">
                  <mat-autocomplete #auto4="matAutocomplete"
                    (optionSelected)="onChangeContratRoomTypeForPrice($event.option.value,index)">
                    <mat-option *ngFor="let RoomType of fltBasePRoomType$| async" [value]="RoomType.room_type_name">
                      {{RoomType.room_type_name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error class="form-text text-danger" *ngIf="basePricePerPeriod.at(index).get('room_type_name').errors?.['required'] ">
                    {{'Room Type  is required' | translate}}
                  </mat-error>
                </mat-form-field>
  
                <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Board' | translate}}</mat-label>
                  <input matInput formControlName="board_name"  required
                    [matAutocomplete]="auto6">
                  <mat-autocomplete #auto6="matAutocomplete"
                    (optionSelected)="onChangeContratBoardForPrices($event.option.value,index)">
                    <mat-option *ngFor="let Board of filterBoardingChild$| async" [value]="Board.board_name">
                      {{Board.board_name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error class="form-text text-danger" *ngIf="basePricePerPeriod.at(index).get('board_name').errors?.['required'] ">
                    {{'Board is required' | translate}}
                  </mat-error>
                </mat-form-field>
              </div> 
            
              <div class="flex flex-col sm:flex-row sm:gap-4">
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Price' | translate}}</mat-label>
                <input matInput formControlName="price" type="number" (change)="price($event.target,index)" required>
                <mat-error *ngIf="basePricePerPeriod.at(index).get('price').hasError('required')">{{'Price is required' | translate}}</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Operation' }}</mat-label>
                <mat-select formControlName="operation" #operation >
                  <mat-option [value]="option.value" *ngFor="let option of options">
                    {{ option.display }}
                  </mat-option>
                </mat-select>
               
              </mat-form-field>

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'EXTB/Price' | translate}}</mat-label>
                <input matInput formControlName="extb_price" type="number" (change)="extb($event.target,index)" required>
                <mat-error *ngIf="basePricePerPeriod.at(index).get('extb_price').hasError('required')">{{'EXTB/Price is required' | translate}}</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Type Calcul' | translate}}</mat-label>
                <input matInput formControlName="par_pax_name"  required
                [matAutocomplete]="autoPax">
              <mat-autocomplete #autoPax="matAutocomplete"
                (optionSelected)="showSelect($event.option.value,index)">
                <mat-option *ngFor="let Calcul of CalculList" [value]="Calcul.value">
                  {{Calcul.viewValue}}
                </mat-option>
              </mat-autocomplete>
                
                <mat-error class="form-text text-danger" *ngIf="basePricePerPeriod.at(index).get('par_pax_name').errors?.['required'] ">
                  {{'Type Calcul is required' | translate}}
                </mat-error>
              </mat-form-field>
            </div>

            <div style="margin-left: 89%;">

              <button
               mat-flat-button 
               mat-button color="primary" (click)="saveBasePrice(tab.id,index)"  [disabled]="tab.pressedBasePrice">{{'Apply Base Prices' | translate}} </button>

            </div>
              </div>
            </div>

          </form>
   
<br>
<!-- <****************************************************************************************************> -->


<mat-card class=" custom-card">
  <div >
    <h4 class="custom-title">
      {{'Rooms'}}
    </h4>
   
  </div>

  <mat-divider></mat-divider>  <mat-table #table [dataSource]="dataSourceRooms" class=" custom-table">
    <!-- table dynamic columns -->
    
    <ng-container *ngFor="let column of columnsRoom " [matColumnDef]="column.columnDef">
      
      <ng-container *ngIf="column.header == 'Room'">
        <mat-header-cell *matHeaderCellDef class="custom-header">{{ column.header }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="custom-cell">{{ row['room_name'] }}</mat-cell>
      </ng-container>

      <ng-container *ngIf="column.header == 'Price'">
        <mat-header-cell *matHeaderCellDef class="custom-header">{{ column.header }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="custom-cell">
          <input matInput placeholder="" class="price-input" 
            (input)="showvalueinput($event,column.header,row['room_id'], row['indexroom'],row['id'],index)" 
            required [value]="row['Price']">
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.header == 'EXTBPrice'">
        <mat-header-cell *matHeaderCellDef class="custom-header">{{ column.header }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="custom-cell">
          <input matInput placeholder="" class="price-input" 
            (input)="showvalueinput($event,column.header,row['room_id'], row['indexroom'],row['id'],index)" 
            required [value]="row['EXTBPrice']">
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.header == 'TypeCalculat'">
        <mat-header-cell *matHeaderCellDef class="custom-header">{{ column.header }}</mat-header-cell>
        <mat-cell *matCellDef="let row; let index1=index" class="custom-cell">
          <input type="radio" name="{{index1}}" id="{{index1}}" 
            (change)="showSelectForRoom($event,column.header,row['indexroom'],row['id'],'Per Room')" 
            [checked]="row['TypeCalculat'] == false" />
          <mat-label style="padding: 3%;">{{'Per Room' | translate}}</mat-label>

          <input type="radio" name="{{index1}}" id="{{index1}}" 
            (change)="showSelectForRoom($event,column.header,row['indexroom'],row['id'],'Per Pax')" 
            [checked]="row['TypeCalculat'] == true" />
          <mat-label style="padding: 3%;">{{'Per Pax' | translate}}</mat-label>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.header == 'Cancellations'">
        <mat-header-cell *matHeaderCellDef class="custom-header">{{ column.header }}</mat-header-cell>
        <mat-cell *matCellDef="let row let i = index" class="custom-cell">
          <button  color="primary" mat-flat-button (click)="openCancellationRoom(Periodes[index],i)"  class="circular-button">
            <mat-icon class="mat-icon" matPrefix svgIcon="mat:policy"></mat-icon>
          </button>
        </mat-cell>
      </ng-container>

    </ng-container>

    <mat-header-row *matHeaderRowDef="columnToDisplay" class="custom-header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnToDisplay" class="custom-row"></mat-row>
  </mat-table>
</mat-card>


          <br>
          <mat-card class=" custom-card">

            <h4 class="custom-title"> {{'Room Type' | translate}}</h4>

          <mat-table #table [dataSource]="dataSourceRoomstype" class="custom-table">

            <!-- table dynamic columns -->
            <ng-container *ngFor="let colums of columnsRoomtype  " [matColumnDef]="colums.columnDeftype"
              matColumnDef="type">

              <ng-container *ngIf="colums.header=='RoomType'">
                <mat-header-cell *matHeaderCellDef class="custom-header">{{ colums.header }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="custom-cell">{{ row['typeName'] }}</mat-cell>
              </ng-container>

              <ng-container *ngIf="colums.header== 'Price'">
                <mat-header-cell *matHeaderCellDef class="custom-header">
                  {{ colums.header }}</mat-header-cell>

                <mat-cell *matCellDef="let row;" class="custom-cell">
                  <input matInput placeholder="" class="price-input"
                    (blur)="showvalueTypeRoom($event,colums.header,row['room_type_id'] ,row['indexroomtype'],row['id'],index)"
                    required [value]="row['Price']">
                </mat-cell>

              </ng-container>
              <ng-container *ngIf="colums.header== 'EXTBPrice' ">
                <mat-header-cell *matHeaderCellDef class="custom-header">
                  {{ colums.header }}</mat-header-cell>

                <mat-cell *matCellDef="let row;" class="custom-cell">
                  <input matInput placeholder="" class="price-input"
                    (blur)="showvalueTypeRoom($event,colums.header,row['room_type_id'] ,row['indexroomtype'],row['id'],index)"
                    required [value]="row['EXTBPrice']">
                </mat-cell>

              </ng-container>
              <ng-container *ngIf="colums.header=='TypeCalculat'">
                <mat-header-cell *matHeaderCellDef class="custom-header">
                  {{ colums.header }}</mat-header-cell>
                <mat-cell *matCellDef="let row;let index11=index" class="custom-cell">

                  <input type="radio" name="{{index11}}+" id="{{index11}}+"
                    (change)="showSelectForTypeRoom($event,colums.header,row['indexroomtype'],row['id'],'Per Room')"
                    [checked]="row['TypeCalculat']== false" />

                  <mat-label style="padding: 3%;"> {{'Per Room' | translate}}</mat-label>
                  <input type="radio" name="{{index11}}+" id="{{index11}}+"
                    (change)="showSelectForTypeRoom($event,colums.header,row['indexroomtype'],row['id'],'Per Pax')"
                    [checked]="row['TypeCalculat'] == true" />

                  <mat-label style="padding: 3%;"> {{'Per Pax' | translate}}</mat-label>
                </mat-cell>

           
              </ng-container>
              <ng-container *ngIf="colums.header === 'Cancellations'">
                <mat-header-cell *matHeaderCellDef class="custom-header">{{ colums.header }}</mat-header-cell>
                <mat-cell *matCellDef="let row let j = index" class="custom-cell">
                  <button  color="primary" mat-flat-button [disabled]="true" (click)="openCancellationRoomType(Periodes[index],j)" class="circular-button">
                    <mat-icon class="mat-icon" matPrefix svgIcon="mat:policy"></mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnToDisplaye" class="custom-header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnToDisplaye " class="custom-row"></mat-row>
          </mat-table>
        </mat-card>

          <br>
          <mat-card class=" custom-card">

          <h4 class="custom-title"> {{'Board' | translate}}</h4>
          <mat-table #table [dataSource]="dataSourceBoard" class="custom-table">

            <!-- table dynamic columns -->
            <ng-container *ngFor="let column of columnsBoard " [matColumnDef]="column.columnDefBoard"
              matColumnDef="board">
              <ng-container *ngIf="column.header=='Board'">
                <mat-header-cell *matHeaderCellDef class="custom-header">{{ column.header }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="custom-cell">{{ row['Board'] }}</mat-cell>
              </ng-container>
              <ng-container *ngIf="column.header== 'Price'">
                <mat-header-cell *matHeaderCellDef class="custom-header">
                  {{ column.header }}</mat-header-cell>

                <mat-cell *matCellDef="let row;" class="custom-cell">
                  <input matInput placeholder="" class="price-input"
                    (blur)="showvalueBoard($event,column.header,row['arrangement_id'], row['indexboard'],row['id'],index)"
                    required [value]="row['Price']">
                </mat-cell>

              </ng-container>
              <ng-container *ngIf="column.header== 'EXTBPrice' ">
                <mat-header-cell *matHeaderCellDef class="custom-header">
                  {{ column.header }}</mat-header-cell>

                <mat-cell *matCellDef="let row;" class="custom-cell">
                  <input matInput placeholder="" class="price-input"
                    (blur)="showvalueBoard($event,column.header,row['arrangement_id'], row['indexboard'],row['id'],index)"
                    required [value]="row['EXTBPrice']">
                </mat-cell>

              </ng-container>
              <ng-container *ngIf="column.header=='TypeCalculat'">
                <mat-header-cell *matHeaderCellDef class="custom-header">
                  {{ column.header }}</mat-header-cell>
                <mat-cell *matCellDef="let row;let index12=index" class="custom-cell">

                  <input type="radio" name="{{index12}}'+'" id="{{index12}}'+'"
                    (change)="showSelectForBoard($event,column.header,row['indexboard'],row['id'],'Per Room')"
                    [checked]="row['TypeCalculat']== false" />

                  <mat-label style="padding: 3%;"> {{'Per Room' | translate}}</mat-label>
                  <input type="radio" name="{{index12}}'+'" id="{{index12}}'+'"
                    (change)="showSelectForBoard($event,column.header,row['indexboard'],row['id'],'Per Pax')"
                    [checked]="row['TypeCalculat'] == true" />

                  <mat-label style="padding: 3%;"> {{'Per Pax' | translate}}</mat-label>
                </mat-cell>

              </ng-container>
              <ng-container *ngIf="column.header === 'Cancellations'">
                <mat-header-cell *matHeaderCellDef class="custom-header">{{ column.header }}</mat-header-cell>
                <mat-cell *matCellDef="let row let k = index" class="custom-cell">
                  <button color="primary" mat-flat-button [disabled]="true" (click)="openCancellationBoard(Periodes[index],k)" class="circular-button">
                    <mat-icon class="mat-icon" matPrefix svgIcon="mat:policy"></mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

            </ng-container>

            <mat-header-row *matHeaderRowDef="columnToDisplayeBoard" class="custom-header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnToDisplayeBoard " class="custom-row"></mat-row>
          </mat-table>
        </mat-card>

          <br>



          <div style="padding: 1%;">

            <button class="float-right" style=" margin: auto; margin: auto; 
            margin-right: inherit; margin-right:initial" mat-flat-button color="primary" [disabled]="tab.pressed"
  (click)="savePrices(tab.id,index)">{{'Update Prices' | translate}}
            </button>
          </div>
          <!-- <br>
          <button class="float-right" style=" margin: auto; margin: auto; 
      margin-right: inherit; margin-right:initial" mat-flat-button color="accent" (click)="savecalculate()">
            {{'Calculate All' | translate}} </button>
          <h4 class="body-2 text-secondary m-0 title ng-tns-c304-238"> {{'Prices' | translate}}*</h4><br>
          <mat-table #table [dataSource]="dataSourcePrices" class="mat-elevation-z8">

            <ng-container matColumnDef="code" style="width: max-content; white-space: pre-line;  ">
              <mat-header-cell *matHeaderCellDef> {{'Code' | translate}}. </mat-header-cell>
              <mat-cell *matCellDef="let row" style="width: max-content;white-space: pre-line;  "> {{row.code}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name" style="width: max-content;white-space: pre-line; ">
              <mat-header-cell *matHeaderCellDef> {{'Name' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let row" style="width: max-content;white-space: pre-line;  "> {{row.name}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="start_at" style="width: max-content;white-space: pre-line;  ">
              <mat-header-cell *matHeaderCellDef> {{'Start At' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let row" style="width: max-content;white-space: pre-line;  "> {{row.start_at}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="end_at" style="width: max-content; white-space: pre-line; ">
              <mat-header-cell *matHeaderCellDef> {{'End At' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let row" style="width: max-content;white-space: pre-line; "> {{row.end_at}}
              </mat-cell>
            </ng-container>







            <ng-container matColumnDef="room_name" style="width: max-content; white-space: pre-line;  ">
              <mat-header-cell *matHeaderCellDef> {{'Room Name' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.room_name}} </mat-cell>
            </ng-container>



            <ng-container matColumnDef="type_name" style="width: max-content; white-space: pre-line;  ">
              <mat-header-cell *matHeaderCellDef> {{'Type Name' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.type_name}} </mat-cell>
            </ng-container>





            <ng-container matColumnDef="board_name" style="width: max-content; white-space: pre-line;  ">
              <mat-header-cell *matHeaderCellDef> {{'Board Name' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.board_name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="price" style="width: max-content;white-space: pre-line; ">
              <mat-header-cell *matHeaderCellDef> {{'Price' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.price}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="price_with_extb" style="width: max-content; white-space: pre-line;  ">
              <mat-header-cell *matHeaderCellDef> {{'EXTB Price' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.price_with_extb}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnToDisplayePrices"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnToDisplayePrices "></mat-row>

          </mat-table> -->




        </div>

      </mat-tab>
    </mat-tab-group>
   

   
    <!-- <div >
      <button  color="primary" mat-flat-button type="submit">{{'Update Contract' | translate}}</button>
    </div> -->

  </mat-step>



</mat-stepper>
